import { template as template_08bf21b6a1454ad68eeaa7c8f03a5af3 } from "@ember/template-compiler";
const WelcomeHeader = template_08bf21b6a1454ad68eeaa7c8f03a5af3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_ef50e2b5ce5544eb93dec36d13118abe } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ef50e2b5ce5544eb93dec36d13118abe(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
